<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ this.formTitle | capitalize }}
      </v-card-title>
      <div v-if="(generateDocument || sendDocument) && mode == Enums.FORM_MODE.UPDATE">
        </BR>
        <p v-if="generateDocument" class="font-weight-regular text-h6 text-center">
          I documenti che sceglierai di generare sostituiranno quelli esistenti
        </p> 
        <p v-else class="font-weight-regular text-h6 text-center">
          Seleziona i documenti da inviare alla scuola
        </p>       
        <v-container>
          <v-col class="form-group">
            <v-data-table
              v-model="selectedTemplates"
              :headers="headers"
              :items="items"
              item-value="name"
              show-select
              class="elevation-1"
            ></v-data-table>
          </v-col>
          <v-col v-if="generateDocument" class="form-group">
            <DatePicker
              v-validate.immediate="'required'"
              :label="$t('forms.agreements.custom_date')"
              v-model="form.custom_date"
              name="custom_date"
              @input="handleDateChange"
            ></DatePicker>
            <span
              :class="Settings.style.class.formFieldRequiredCaption"
              v-if="veeErrors.has('custom_date')"
              >{{ veeErrors.first("custom_date") }}</span
            >
          </v-col>
        </v-container>
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </div>
      <div v-else>
      <v-stepper v-model="e6_step" vertical>
        <v-stepper-step :complete="e6_step > 1" step="1">{{
          $t("forms.agreements.section1")
        }}</v-stepper-step>
        <v-stepper-content step="1">
          <v-form @submit.prevent="onSubmit" data-vv-scope="section1">
            <div class="form-group required">
              <CustomerAutocomplete
                :mode="mode"
                v-model="form.customer"
                itemText="name"
                itemValue="id"
                :returnObject="true"
                :label="$t('forms.agreements.customer')"
                v-validate.immediate="'required'"
                :disabled="
                  mode == Enums.FORM_MODE.UPDATE || this.withModelId != null
                "
                @input="customerSelected"
                :fetchFunction="ajaxCustomerFetchAll"
                :getter="customers"
                :withModelId="withModelId"
                name="customer"
              />
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.customer')"
                >{{ veeErrors.first("section1.customer") }}</span
              >
            </div>
            <div class="form-group required">
              <v-select
                v-model="form.services"
                :items="this.tenantServices"
                item-text="name"
                item-value="id"
                return-object
                :label="$t('forms.agreements.service')"
                :disabled="
                  _.isEmpty(form.customer) ||
                    (renewalMode && !advancedRenewalMode)
                "
                v-validate.immediate="'required'"
                @input="selectInput()"
                multiple
                name="service"
              ></v-select>
              <span
                :class="Settings.style.class.formFieldRequiredCaption"
                v-if="veeErrors.has('section1.service')"
                >{{ veeErrors.first("section1.service") }}</span
              >
            </div>
            <v-container>
              <v-row>
                <v-col class="form-group">
                  <v-text-field
                    :label="$t('forms.agreements.cig')"
                    v-model="form.cig"
                    name="cig"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group">
                  <v-text-field
                    :label="$t('forms.agreements.cost')"
                    v-model="form.outgoing"
                    name="cost"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group">
                  <v-select
                    v-model="form.metadata.workflow_contratto"
                    :items="this.workflow_contratto"
                    item-text="name"
                    item-value="id"
                    :label="$t('forms.agreements.flow')"
                    name="flow"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <!-- div class="form-group">
              <v-text-field
                :label="$t('forms.agreements.cig')"
                v-model="form.cig"
                name="cig"
              ></v-text-field>
            </div>
            <div class="form-group">
              <v-text-field
                :label="$t('forms.agreements.cost')"
                v-model="form.outgoing"
                name="cost"
              ></v-text-field>
            </div>
            <div class="form-group">
              <v-select
                v-model="form.metadata.workflow_contratto"
                :items="this.workflow_contratto"
                item-text="name"
                item-value="id"
                :label="$t('forms.agreements.flow')"
                name="flow"
              ></v-select>
            </div -->
            <v-container>
              <v-row>
                <v-col class="form-group">
                  <DatePicker
                    v-validate.immediate="'required'"
                    :label="$t('forms.agreements.begin_date')"
                    v-model="form.start_date"
                    name="start_date"
                    @input="handleDateChange"
                  ></DatePicker>
                  <span
                    :class="Settings.style.class.formFieldRequiredCaption"
                    v-if="veeErrors.has('section1.start_date')"
                    >{{ veeErrors.first("section1.start_date") }}</span
                  >
                </v-col>
                <v-col class="form-group">
                  <DatePicker
                    :label="$t('forms.agreements.end_date')"
                    v-model="form.end_date"
                    name="end_date"
                  ></DatePicker>
                </v-col>
                <v-col class="form-group">
                  <v-checkbox
                    :label="$t('forms.agreements.infinite_deadline')"
                    v-model="form.infinite_deadline"
                    name="infinite_deadline"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <div class="form-group">
              <v-textarea
                :label="$t('forms.agreements.details')"
                v-model="form.note"
                name="details"
                auto-grow
                rows="1"
              ></v-textarea>
            </div>
            <v-container v-show="!renewalMode">
              <DisplaySeparator
              :as="Enums.DISPLAY_AS.SEPARATOR"
              :label="'Sezione preventivi'"
              ></DisplaySeparator>
              <v-row>
                <v-col class="form-group">
                  <DatePicker
                    :label="$t('forms.agreements.renewal_proposal_sent_at')"
                    v-model="form.metadata.renewal_proposal_sent_at"
                    name="renewal_proposal_date"
                  ></DatePicker>
                </v-col>
                <v-col class="form-group">
                  <v-text-field
                    :label="$t('forms.agreements.renewal_proposal_id')"
                    v-model="form.metadata.renewal_proposal_id"
                    name="renewal_proposal_id"
                  ></v-text-field>
                </v-col>
                <v-col class="form-group">
                  <v-checkbox
                    :label="$t('forms.agreements.disable_preventivo')"
                    v-model="form.metadata.disable_preventivo"
                    name="disable_preventivo"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- div class="form-group">
                <DatePicker
                  :label="$t('forms.agreements.renewal_proposal_sent_at')"
                  v-model="form.metadata.renewal_proposal_sent_at"
                  name="renewal_proposal_date"
                ></DatePicker>
              </div>
              <div class="form-group">
                <v-text-field
                  :label="$t('forms.agreements.renewal_proposal_id')"
                  v-model="form.metadata.renewal_proposal_id"
                  name="renewal_proposal_id"
                ></v-text-field>
              </div -->
            </v-container>
            <v-container>
            <DisplaySeparator
              :as="Enums.DISPLAY_AS.SEPARATOR"
              :label="$t('labels.metadataAT')"
            />
            <v-row>
              <v-col class="form-group">
                <v-text-field
                  :label="$t('forms.agreements.metadata_agreements.username')"
                  v-model="form.metadata_agreements.username_at"
                ></v-text-field>
              </v-col>
              <v-col class="form-group">
                <v-text-field
                  :label="$t('forms.agreements.metadata_agreements.password')"
                  v-model="form.metadata_agreements.password_at"
                ></v-text-field>
              </v-col>
              <v-col class="form-group">
                <v-text-field
                  :label="$t('forms.agreements.metadata_agreements.pin')"
                  v-model="form.metadata_agreements.pin_at"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-container>
            <!-- BUTTONS -->
            <FormButtons
              :multiForm="true"
              :multiLayout="0"
              @onNext="onNext"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section1')"
            />
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="e6_step > 2" step="2">{{
          $t("forms.agreements.section2")
        }}</v-stepper-step>
        <v-stepper-content step="2">
          <v-form @submit.prevent="onSubmit" data-vv-scope="section2">
            <template v-for="(agreementService, i) in form.agreementServices">
              <v-container v-bind:key="i">
                <v-row>
                  <v-col cols="12">{{
                    getServiceName(agreementService)
                  }}</v-col>
                  <v-col cols="3" class="form-group">
                    <v-text-field
                      :label="
                        !form.infinite_deadline
                          ? `${$t('forms.agreements.amount')}`
                          : `${$t('forms.agreements.annual_amount')}`
                      "
                      placeholder="0.00"
                      v-model="agreementService.metadata.amount"
                      :name="`amount-${[i]}`"
                      v-validate.immediate="'required'"
                      @input="handleAmountChange"
                    ></v-text-field>
                    <span
                      :class="Settings.style.class.formFieldRequiredCaption"
                      v-if="veeErrors.has(`section2.amount-${[i]}`)"
                      >{{ veeErrors.first(`section2.amount-${[i]}`) }}</span
                    >
                  </v-col>
                  <v-col cols="3" class="form-group">
                  <v-select                    
                    v-model="agreementService.metadata.mepa_code"
                    :label="$t('forms.agreements.mepa_code')"
                    :items="mepaCodes"
                    item-text="code"
                    item-value="id"
                    :name="`code-${[i]}`"
                    >
                  </v-select>
                 </v-col>
                  <template v-if="getServiceName(agreementService) === 'GDPR'">
                    <template
                      v-for="(value, j) in getMetadata(agreementService)"
                    >
                      <v-col
                        cols="3"
                        :key="j"
                        v-if="value !== 'amount' && value != 'start_date' && value !== 'mepa_code'"
                      >
                        <v-select
                          :label="$t('forms.agreements.' + value)"
                          :items="options[value]"
                          item-text="name"
                          item-value="id"
                          v-model="agreementService.metadata[value]"
                        ></v-select>
                      </v-col>
                    </template>
                  </template>
                  <template
                    v-if="
                      getServiceName(agreementService) === 'ASSISTENZA_TECNICA'
                    "
                  >
                    <template
                      v-for="(value, j) in getMetadata(agreementService)"
                    >
                      <v-col
                        cols="3"
                        :key="j"
                        v-if="value !== 'amount' && value != 'start_date'"
                      >
                        <v-text-field
                          :label="$t('forms.agreements.' + value)"
                          v-model="agreementService.metadata[value]"
                        ></v-text-field>
                      </v-col>
                    </template>
                  </template>
                </v-row>
                <div class="form-group">
                  <v-textarea
                    :label="$t('forms.agreements.preventivo_details')"
                    v-model="agreementService.metadata.preventivo_details"
                    name="preventivo_details"
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </div>
              </v-container>
            </template>
            <FormButtons
              :multiForm="true"
              :multiLayout="1"
              @onBack="onBack"
              @onNext="onNext"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section2')"
            />
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="e6_step > 3" step="3">{{
          $t("forms.agreements.section3")
        }}</v-stepper-step>
        <v-stepper-content step="3">
          <v-form @submit.prevent="onSubmit" data-vv-scope="section3">
            <v-container>
              <v-row>
                <v-col cols="4" class="form-group">
                  <v-select
                    :label="`${$t('forms.agreements.external')}`"
                    v-model="form.invoice_data.external"
                    name="external"
                    :items="this.external"
                    item-text="text"
                    item-value="value"
                    :disabled="
                      mode != Enums.FORM_MODE.CREATE && !this.$props.renewalMode
                    "
                  ></v-select>
                </v-col>
                <v-col cols="4" class="form-group">
                  <v-text-field
                    :label="$t('forms.agreements.billing_frequency')"
                    v-model="form.invoice_data.billing_period"
                    name="billing_period"
                    :disabled="
                      (mode != Enums.FORM_MODE.CREATE && !this.$props.renewalMode) ||
                      (form.invoice_data.external)
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="form-group">
                  <v-select
                    :label="
                      `${$t('forms.agreements.invoicing')} ${$t(
                        'forms.agreements.early'
                      )}`
                    "
                    v-model="form.invoice_data.early"
                    name="early"
                    :items="this.early"
                    item-text="text"
                    item-value="value"
                    :disabled="
                      (mode != Enums.FORM_MODE.CREATE && !this.$props.renewalMode) ||
                      (form.invoice_data.external)
                    "
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <FormButtons
              :multiForm="true"
              :multiLayout="1"
              @onBack="onBack"
              @onNext="onNext"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section3')"
            />
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="e6_step > 4" step="4">{{
          $t("forms.agreements.section4")
        }}</v-stepper-step>
        <v-stepper-content step="4">
          <v-container>
            <v-row>
              <h5>
                {{ $t("forms.agreements.review") }}
                {{ $t("forms.agreements.section1") }}
              </h5>
            </v-row>
            <br />
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.tenant")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{ this.tenantName }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.customer")
              }}</v-col>
              <v-col cols="4" class="summary-font">{{
                this.form.customer ? this.form.customer.name : ""
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.start_date")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{
                form.start_date | fullDate
              }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.end_date")
              }}</v-col>
              <v-col cols="3" class="summary-font">{{
                form.end_date | fullDate
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.amount")
              }}</v-col>
              <v-col cols="2" class="summary-font"
                >{{ form.total_amount }} €</v-col
              >
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.outgoing")
              }}</v-col>
              <v-col cols="4" class="summary-font">{{ form.outgoing }} €</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.cig")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{ form.cig }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.workflow_contratto")
              }}</v-col>
              <v-col cols="4" class="summary-font">{{
                getEnumTranslationFor(
                  "agreements",
                  form.metadata.workflow_contratto
                )
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.renewal_proposal_id")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{
                form.metadata.renewal_proposal_id
              }}</v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.renewal_proposal_sent_at")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{
                form.metadata.renewal_proposal_sent_at | fullDate
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.disable_preventivo")
              }}</v-col>
              <v-col cols="10" class="summary-font">{{ form.metadata.disable_preventivo ? $t("labels.yes") : $t("labels.no") }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="summary-font-bold">{{
                $t("forms.agreements.details")
              }}</v-col>
              <v-col cols="10" class="summary-font">{{ form.note }}</v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <h5>
                {{ $t("forms.agreements.review") }}
                {{ $t("forms.agreements.section2") }}
              </h5>
            </v-row>
            <br />
            <template v-for="(agreementService, i) in form.agreementServices">
              <v-row v-bind:key="i" style="margin-bottom: 20px;">
                <v-col cols="4" class="summary-font-bold">{{
                  getServiceName(agreementService)
                }}</v-col>
                <template v-for="(value, j) in getMetadata(agreementService)">
                  <template v-if="value != 'start_date'">
                    <v-col :key="j + 'a'" cols="4" class="summary-font-bold">{{
                      $t("forms.agreements." + value)
                    }}</v-col>
                    <v-col :key="j + 'b'" cols="4" class="summary-font">{{
                      getTranslation(value, agreementService.metadata[value])
                    }}</v-col>
                    <!--<v-col :key="j + 'c'" cols="4" class="summary-font" v-else>{{
                      agreementService.metadata[value] | fullDate
                    }}</v-col>-->
                    <v-col :key="j + 'd'" cols="4"></v-col>
                  </template>
                </template>
              </v-row>
            </template>
            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <h5>
                {{ $t("forms.agreements.review") }}
                {{ $t("forms.agreements.section3") }}
              </h5>
            </v-row>
            <br />
            <v-row>
              <v-col cols="3" class="summary-font-bold">{{
                $t("forms.agreements.external")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{
                form.invoice_data.external ? $t("labels.yes") : $t("labels.no")
              }}</v-col>
            </v-row>
            <v-row v-if="!form.invoice_data.external">
              <v-col cols="3" class="summary-font-bold">{{
                $t("forms.agreements.billing_frequency")
              }}</v-col>
              <v-col cols="2" class="summary-font">{{
                form.invoice_data.billing_period
              }}</v-col>
            </v-row>
            <v-row v-if="!form.invoice_data.external">
              <v-col cols="3" class="summary-font-bold"
                >{{ $t("forms.agreements.invoicing") }}
                {{ $t("forms.agreements.early") }}</v-col
              >
              <v-col cols="4" class="summary-font">{{
                form.invoice_data.early ? $t("labels.yes") : $t("labels.no")
              }}</v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
          </v-container>
          <v-form @submit.prevent="onSubmit" data-vv-scope="section4">
            <FormButtons
              :multiForm="true"
              :multiLayout="2"
              :createLoading="createLoading"
              @onBack="onBack"
              @onSave="onSubmit"
              @onCancel="onCancel"
              :disabled="veeErrors.any('section4')"
            />
          </v-form>
        </v-stepper-content>
      </v-stepper>
      </div>
    </div>
    <div v-if="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV
        :label="$t('forms.agreements.customer')"
        :value="this.form.customer.name"
      />
      <DisplayKV
        :label="$t('forms.agreements.tenant')"
        :value="this.form.tenant.name"
      />
      <DisplayKV
        :label="$t('forms.agreements.flow')"
        :value="
          getEnumTranslationFor('agreements', form.metadata.workflow_contratto)
        "
      />
      <DisplayKV
        :label="$t('forms.agreements.service')"
        :value="this.form.service"
      />
      <DisplayKV
        :label="$t('forms.agreements.state')"
        :value="getEnumTranslationFor('agreements', this.form.state)"
      />
      <DisplayKV :label="$t('forms.agreements.cig')" :value="this.form.cig" />
      <DisplayKV
        :label="$t('forms.agreements.amount')"
        :value="this.form.total_amount"
      />
      <DisplayKV
        :label="$t('forms.agreements.cost')"
        :value="this.form.outgoing"
      />
      <DisplayKV
        :label="$t('forms.agreements.worked_hours')"
        :value="this.form.worked_hours"
      />
      <DisplayKV
        :label="$t('forms.agreements.details')"
        :value="this.form.note"
      />
      <DisplayKV
        :label="$t('forms.agreements.begin_date')"
        :value="this.form.start_date | fullDate"
      />
      <DisplayKV
        :label="$t('forms.agreements.end_date')"
        :value="this.form.end_date | fullDate"
      />
      <DisplayKV
        :label="$t('forms.agreements.last_mail_date')"
        :value="this.form.last_email_sent_date"
      />
      <DisplayKV
        :label="$t('forms.agreements.mail_sent')"
        :value="this.form.email_sent_count"
      />

      <DisplaySeparator
        :as="Enums.DISPLAY_AS.SEPARATOR"
        :label="$t('labels.metadataAT')"
      />

      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.agreements.metadata_agreements.username')"
        :value="this.form.metadata_agreements.username_at"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.agreements.metadata_agreements.password')"
        :value="this.form.metadata_agreements.password_at"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.agreements.metadata_agreements.pin')"
        :value="this.form.metadata_agreements.pin_at"
      />

    </div>
    <div v-if="mode === Enums.FORM_MODE.SHOW_SUMMARY" class="form-body-show">
      <DisplayKV
        :label="$t('forms.agreements.customer')"
        :value="this.form.customer.name"
      />
      <DisplayKV
        :label="$t('forms.agreements.tenant')"
        :value="this.form.tenant.name"
      />
      <DisplayKV
        :label="$t('forms.agreements.flow')"
        :value="
          getEnumTranslationFor('agreements', form.metadata.workflow_contratto)
        "
      />
    </div>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import DisplaySeparator from "@/components/Shared/DisplaySeparator";
import _ from "lodash";
import DatePicker from "@/components/Shared/DatePicker";
import CustomerAutocomplete from "@/components/Customer/CustomerAutocomplete";
import FormButtons from "@/components/Shared/FormButtons";
import { HelperService } from "@/services/helper.service";
import moment from "moment";

export default {
  name: "AgreementForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    renewalMode: {
      default: false,
      type: Boolean
    },
    advancedRenewalMode: {
      default: false,
      type: Boolean
    },
    generateDocument: {
      default: false,
      type: Boolean
    },
    sendDocument: {
      default: false,
      type: Boolean
    },
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      //agreementsSetted: [],
      progress: true,
      resourceType: this.$t("resource_types.agreement"),
      options: {
        nomina: [
          { id: "TO_DO" },
          { id: "SENT_TO_CLIENT" },
          { id: "SIGNED_BY_CLIENT" },
          { id: "SIGNED_BY_TENANT" },
          { id: "FINISHED" }
        ],
        comunicazione_garante: [{ id: "TO_DO" }, { id: "DONE" }],
        pagina_pubblica: [
          { id: "NOT_EXIST" },
          { id: "NOT_LINKED" },
          { id: "LINKED" }
        ]
      },
      workflow_contratto: [
        { id: "TO_DO" },
        { id: "SENT_TO_CLIENT" },
        { id: "SIGNED_BY_CLIENT" },
        { id: "SIGNED_BY_TENANT" },
        { id: "FINISHED" }
      ],
      tenantServices: [],
      form: {
        id: "",
        code: "",
        metadata: {},
        customer: {},
        tenant: {},
        service: "",
        services: [],
        state: "",
        cig: "",
        total_amount: "",
        outgoing: 0,
        worked_hours: 0,
        note: "",
        start_date: "",
        end_date: "",
        last_email_sent_date: "",
        email_sent_count: null,
        agreementServices: [],
        infinite_deadline: false,
        invoice_data: {},
        custom_date: "",
        metadata_agreements: {
          username_at: "",
          password_at: "",
          pin_at: ""
        }
      },
      e6_step: 1,
      createLoading: false,
      tenantName: "",
      early: [
        {
          text: this.$t("labels.yes"),
          value: true
        },
        {
          text: this.$t("labels.no"),
          value: false
        }
      ],
      external: [
        {
          text: this.$t("labels.yes"),
          value: true
        },
        {
          text: this.$t("labels.no"),
          value: false
        }
      ],
      selectedTemplates: [],
      headers: [],
      items: []
    };
  },
  components: { DisplayKV, DisplaySeparator, DatePicker, FormButtons, CustomerAutocomplete },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("agreement", [
      "ajaxAgreementCreateOrUpdate",
      "ajaxAgreementDelete"
    ]),
    ...mapActions("service", ["ajaxServiceFetchAll"]),
    ...mapActions("mepaCode", ["ajaxMepaCodeFetchAll"]),
    ...mapActions("service", ["ajaxServiceFetchAll"]),
    ...mapActions("customer", ["ajaxCustomerFetchAll"]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),
    ...mapActions("attachmentType", [
      "ajaxAttachmentTypeFetchAll",
      "ajaxAttachmentTypeFetchTemp",
    ]),
    decodeMetadataAgreements(item) {
      let base = {
        username_at: "",
        password_at: "",
        pin_at: ""
      };

      let currentMetadataAgreements = null;
      if (typeof item.metadata_agreements == "object") {
        currentMetadataAgreements = item.metadata_agreements;
      } else {
        const meta = JSON.parse(item.metadata_agreements);
        currentMetadataAgreements = meta;
      }
      return _.merge(base, currentMetadataAgreements);
    },
    encodeMetadataAgreements(item) {
      let base = {
        username_at: "",
        password_at: "",
        pin_at: ""
      };

      let currentMetadataAgreements = item.metadata_agreements || base;
      return JSON.stringify(currentMetadataAgreements);
    },

    handleDateChange(val) {
      this.form.agreementServices.forEach(service => {
        if (!service.id)
          HelperService.agreementServiceChangeStartDate(service, val);
      });
    },
    getTranslation(name, value) {
      if (name == "amount") return value + " €";
      if (name == "total_hours" || name == "preventivo_details") return value;
      if (name == "mepa_code") {
        let mepa_code = _.filter(this.mepaCodes, function(f) { return f.id == value; })
        return mepa_code.length > 0 ? mepa_code[0].code : ""
      }
      return this.getEnumTranslationFor("agreements", value);
    },
    handleAmountChange() {
      this.form.total_amount = HelperService.getTotalAmount(this.form);
    },
    selectInput() {
      for (let i = 0; i < this.form.services.length; i++) {
        let found = false;
        for (let j = 0; j < this.form.agreementServices.length; j++) {
          if (
            this.form.services[i].id ==
            this.form.agreementServices[j].service_id
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          let newAgreement = {
            service_id: Number(this.form.services[i].id),
            metadata: {
              start_date: this.form.start_date
            }
          };
          HelperService.prepareAgreementServiceMetadata(
            this.form,
            newAgreement
          );
          this.form.agreementServices.push(newAgreement);
        }
      }
      for (let i = 0; i < this.form.agreementServices.length; i++) {
        let found = false;
        for (let j = 0; j < this.form.services.length; j++) {
          if (
            this.form.agreementServices[i].service_id ==
            this.form.services[j].id
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.form.agreementServices.splice(i--);
          break;
        }
      }
      this.handleAmountChange();
    },
    async retrieveTenantName() {
      if (this.form.customer) {
        await this.ajaxTenantFetchAll();

        const tenant_id = this.form.customer.tenant_id;
        let tenant = this.tenants.filter(value => value.id == tenant_id);
        if (tenant && tenant.length > 0) {
          this.tenantName = tenant[0].name;
        }
      } else this.tenantName = "";
    },
    getServiceName(agreementService) {
      let serviceName = "";
      if (agreementService) {
        const service_id = agreementService.service_id;
        let service = this.services.filter(value => value.id == service_id);
        if (service && service.length > 0) {
          serviceName = service[0].name;
        }
      }
      return serviceName;
    },
    getMetadata(agreementService) {
      return Object.keys(agreementService.metadata);
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.e6_step = 1;
        this.setForm();
      } else {
        let metadata_agreements = this.decodeMetadataAgreements(this.$props.selectedItem);
        this.$props.selectedItem.metadata_agreements = metadata_agreements;
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      if (this.mode != Enums.FORM_MODE.CREATE) {
        this.retrieveTenantName();
        this.updateTenantServices(this.form.customer);
        if (this.generateDocument || this.sendDocument) {
          this.fetchData();
        } 
      }

      let payloadFAP = HelperService.buildFilterAndPagination({
        filter: { enabled : 1 },
      });
      await this.ajaxMepaCodeFetchAll(payloadFAP);
    },
    async fetchData(needle = null) {
      await this.ajaxAttachmentTypeFetchAll({machineName: "ADMINISTRATIVE_DOCUMENT_TEMPLATE"});
      this.initTable();
    },
    initTable() {
      this.headers = this.mapHeaders();
      this.items = this.mapItems();
    },
    mapHeaders() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Machine Name",
        value: "machine_name",
        align: "start",
        sortable: true,
      });
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true,
      });
      return tableHeaders;
    },
    mapItems() {
      let map = [];
      map = _.map(this.attachmentTypes, (item) => {
        item.fields = {
          machine_name: {
            data: item.machine_name,
            dataType: "text",
            css: "text-xs-left",
          },
          name: { data: item.name, dataType: "text", css: "text-xs-left" },
        };
        return item;
      });
      return map;
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async onBack() {
      this.e6_step--;
    },
    async onNext() {
      this.e6_step++;
    },
    async submitToStore() {
      const payload = _.clone(this.formGetter);
      payload.metadata_agreements = this.encodeMetadataAgreements(payload);
      if (this.generateDocument) {
        payload.action = Enums.GENERATE_OPTION.GENERATE_DOCUMENT;
        payload.templates = this.selectedTemplates.map((item) => item.id);
      } else if (this.sendDocument) {
        payload.action = Enums.GENERATE_OPTION.SEND_DOCUMENT;
        payload.templates = this.selectedTemplates.map((item) => item.id);
      } else {
        if (this.renewalMode) {
          if (this.advancedRenewalMode)
            payload.renewal = Enums.RENEWAL_AGREEMENT_OPTION.ADVANCED_RENEWAL;
          else payload.renewal = Enums.RENEWAL_AGREEMENT_OPTION.SIMPLE_RENEWAL;
        }
      }
      this.createLoading = true;
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxAgreementCreateOrUpdate,
        payload,
        payload => payload.v.code,
        payload => payload.p.code
      );
      this.createLoading = false;
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.code = row.code;
        this.form.metadata = row.metadata;
        this.form.customer = row.customer;
        this.form.tenant = row.tenant;
        this.form.service = row.service;
        this.form.services = row.services;
        this.form.state = row.state;
        this.form.cig = row.cig;
        this.form.total_amount = row.total_amount;
        this.form.outgoing = row.outgoing;
        this.form.worked_hours = row.worked_hours;
        this.form.note = row.note;
        this.form.start_date = row.start_date;
        this.form.end_date = row.end_date;
        this.form.last_email_sent_date = row.last_email_sent_date;
        this.form.email_sent_count = row.email_sent_count;
        this.form.agreementServices = row.agreementServices;
        this.form.invoice_data = row.invoice_data;
        this.form.custom_date = row.start_date;
        this.form.metadata_agreements = row.metadata_agreements;

        if (this.renewalMode) {
          this.form.start_date = moment(this.form.end_date)
            .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
            .add(1, "day")
            .format();
          this.form.end_date = moment(this.form.start_date)
            .add(1, "year")
            .subtract(1, "day")
            .format();
          this.form.cig = "";
        }
      } else if (row == null) {
        this.form.id = "";
        this.form.code = "";
        this.form.metadata = {};
        if (!this.withModelId) this.form.customer = null;
        this.form.tenant = null;
        this.form.service = "";
        this.form.services = [];
        this.form.state = "";
        this.form.cig = "";
        this.form.total_amount = 0;
        this.form.outgoing = 0;
        this.form.worked_hours = 0;
        this.form.note = "";
        this.form.start_date = "";
        this.form.end_date = "";
        this.form.last_email_sent_date = "";
        this.form.email_sent_count = null;
        this.form.agreementServices = [];
        this.form.invoice_data = {};
        this.form.metadata_agreements.username_at = "";
        this.form.metadata_agreements.password_at = "";
        this.form.metadata_agreements.pin_at = "";
      }
    },
    async updateTenantServices(customer) {
      let service_ids = [];

      /*if (this.$props.renewalMode) {
        for (let i = 0; i < this.agreementsSetted.length; i++) {
          const agreementServices = this.agreementsSetted[i].agreementServices;
          service_ids.push(
            agreementServices.map(item => item.service_id).join()
          );
        }
      }*/

      this.tenantServices = [];
      if (!customer) return;
      await this.ajaxServiceFetchAll();
      this.services.forEach(item => {
        if (item.tenant_id == customer.tenant_id) {
          if (service_ids.length == 0 || service_ids.includes(item.id))
            this.tenantServices.push(item);
        }
      });
    },
    async customerSelected() {
      this.retrieveTenantName();

      this.form.services = [];
      this.form.agreementServices = [];
      this.handleAmountChange();
      this.updateTenantServices(this.form.customer);
    }
  },
  computed: {
    ...mapGetters("service", ["services"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("tenant", ["tenants"]),
    ...mapGetters("agreement", ["agreementsTemp"]),
    ...mapGetters("attachmentType", ["attachmentTypes"]),
    ...mapGetters("mepaCode", ["mepaCodes"]),

    formGetter: state => {
      return JSON.parse(JSON.stringify(state.form));
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          if (this.renewalMode)
            title = this.$t("labels.renewResourceTypeName", {
              resourceType: this.resourceType || "-",
              resourceName: this.form.id ? this.form.code : "-"
            });
          else if (this.generateDocument)
            title = this.$t("labels.generateDocumentResourceTypeName", {
              resourceType: this.resourceType || "-",
              resourceName: this.form.id ? this.form.code : "-"
            });
          else if (this.sendDocument)
            title = this.$t("labels.sendDocumentResourceTypeName", {
              resourceType: this.resourceType || "-",
              resourceName: this.form.id ? this.form.code : "-"
            });
          else
            title = this.$t("labels.updateResourceTypeName", {
              resourceType: this.resourceType || "-",
              resourceName: this.form.id ? this.form.code : "-"
            });
          break;
        default:
          break;
      }
      return title;
    },

    Enums() {
      return Enums;
    }
  },
  created() {
    this.arrayTranslation("agreements", this.workflow_contratto, "id", "name");
    this.arrayTranslation("agreements", this.options.nomina, "id", "name");
    this.arrayTranslation(
      "agreements",
      this.options.comunicazione_garante,
      "id",
      "name"
    );
    this.arrayTranslation(
      "agreements",
      this.options.pagina_pubblica,
      "id",
      "name"
    );
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
