var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form-container title", attrs: { fluid: "" } },
    [
      _vm.mode == _vm.Enums.FORM_MODE.CREATE ||
      _vm.mode == _vm.Enums.FORM_MODE.UPDATE
        ? _c(
            "div",
            [
              _c("v-card-title", { staticClass: "form-title title" }, [
                _vm._v(_vm._s(_vm._f("capitalize")(this.formTitle)))
              ]),
              _c(
                "v-form",
                {
                  staticClass: "form-body",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label: _vm.$t("forms.services.name"),
                          name: "name"
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      }),
                      _vm.veeErrors.has("name")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          label:
                            _vm.$t("forms.services.hours") +
                            _vm.$t("forms.services.default_hours"),
                          name: "hours"
                        },
                        model: {
                          value: _vm.form.hours,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "hours", $$v)
                          },
                          expression: "form.hours"
                        }
                      }),
                      _vm.veeErrors.has("hours")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("hours")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "" + _vm.$t("forms.agreements.annual_amount"),
                          placeholder: "0.00"
                        },
                        model: {
                          value: _vm.form.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "amount", $$v)
                          },
                          expression: "form.amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("forms.services.note"),
                          name: "note"
                        },
                        model: {
                          value: _vm.form.note,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$t("forms.services.description"),
                          name: "description"
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group required" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.immediate",
                            value: "required",
                            expression: "'required'",
                            modifiers: { immediate: true }
                          }
                        ],
                        attrs: {
                          items: this.tenants,
                          enabled: !this.$props.withModelId,
                          "item-text": "name",
                          "item-value": "id",
                          label: _vm.$t("forms.services.tenant"),
                          "single-line": "",
                          clearable: "",
                          name: "tenant"
                        },
                        model: {
                          value: _vm.form.tenant_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tenant_id", $$v)
                          },
                          expression: "form.tenant_id"
                        }
                      }),
                      _vm.veeErrors.has("tenant")
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.Settings.style.class
                                  .formFieldRequiredCaption
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("tenant")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("forms.services.self_generated_file")
                        },
                        model: {
                          value: _vm.form.self_generated_file,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "self_generated_file", $$v)
                          },
                          expression: "form.self_generated_file"
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormButtons", {
                    attrs: {
                      withDelete: _vm.mode != _vm.Enums.FORM_MODE.CREATE,
                      disabled: _vm.veeErrors.any()
                    },
                    on: {
                      onDelete: _vm.onDelete,
                      onSave: _vm.onSubmit,
                      onCancel: _vm.onCancel
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === _vm.Enums.FORM_MODE.SHOW,
              expression: "mode === Enums.FORM_MODE.SHOW"
            }
          ],
          staticClass: "form-body-show"
        },
        [
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.id"),
              value: this.form.id
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.name"),
              value: this.form.name
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.amount"),
              value: this.form.amount
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.note"),
              value: this.form.note
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.description"),
              value: this.form.description
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.hours"),
              value: this.form.hours
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.BOOLEAN,
              label: _vm.$t("forms.services.self_generated_file"),
              value: this.form.self_generated_file
            }
          }),
          _c("DisplayKV", {
            attrs: {
              as: _vm.Enums.DISPLAY_AS.TEXT,
              label: _vm.$t("forms.services.public_page"),
              value: this.form.public_page
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }