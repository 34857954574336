<template>
  <v-container fluid class="form-container title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">{{
        this.formTitle | capitalize
      }}</v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.services.name')"
            v-model="form.name"
            name="name"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('name')"
            >{{ veeErrors.first("name") }}</span
          >
        </div>
        <div class="form-group">
          <v-text-field
            :label="
              $t('forms.services.hours') + $t('forms.services.default_hours')
            "
            v-model="form.hours"
            name="hours"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('hours')"
            >{{ veeErrors.first("hours") }}</span
          >
        </div>
        <div class="form-group">
          <v-text-field
            :label="`${$t('forms.agreements.annual_amount')}`"
            placeholder="0.00"
            v-model="form.amount"
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.services.note')"
            v-model="form.note"
            name="note"
          ></v-text-field>
        </div>

        <div class="form-group">
          <v-textarea
            :label="$t('forms.services.description')"
            v-model="form.description"
            name="description"
          ></v-textarea>
        </div>

        <div class="form-group required">
          <v-select
            v-model="form.tenant_id"
            :items="this.tenants"
            :enabled="!this.$props.withModelId"
            item-text="name"
            item-value="id"
            :label="$t('forms.services.tenant')"
            single-line
            clearable
            v-validate.immediate="'required'"
            name="tenant"
          ></v-select>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('tenant')"
            >{{ veeErrors.first("tenant") }}</span
          >
        </div>
      <!--<div class="form-group">
          <v-text-field
            :label="$t('forms.services.public_page')"
            v-model="form.public_page"
            name="public_page"
          ></v-text-field>
        </div>
      --> 
    
        <div class="form-group">
          <v-checkbox
            v-model="form.self_generated_file"
            :label="$t('forms.services.self_generated_file')"
          ></v-checkbox>
        </div>

        <!-- BUTTONS -->
        <FormButtons
          :withDelete="mode != Enums.FORM_MODE.CREATE"
          @onDelete="onDelete"
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
    <div v-show="mode === Enums.FORM_MODE.SHOW" class="form-body-show">
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.id')"
        :value="this.form.id"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.name')"
        :value="this.form.name"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.amount')"
        :value="this.form.amount"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.note')"
        :value="this.form.note"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.description')"
        :value="this.form.description"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.hours')"
        :value="this.form.hours"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.BOOLEAN"
        :label="$t('forms.services.self_generated_file')"
        :value="this.form.self_generated_file"
      />
      <DisplayKV
        :as="Enums.DISPLAY_AS.TEXT"
        :label="$t('forms.services.public_page')"
        :value="this.form.public_page"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import DisplayKV from "@/components/Shared/DisplayKeyValue";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  name: "ServiceForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String
    },
    selectedItem: Object,
    withModelId: {
      type: String,
      required: false
    },
    withModelType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      // progress: true,
      resourceType: this.$t("resource_types.service"),
      alertResult: false,
      form: {
        id: "",
        amount: "",
        hours: "-1",
        name: "",
        note: "",
        description: "",
        public_page: "",
        self_generated_file: false,
        tenant_id: this.$props.withModelId
      }
    };
  },
  components: { DisplayKV, FormButtons },
  mixins: [SharedMixin, Enums],
  watch: {
    selectedItem() {
      this.initForm();
      this.initComp();
    }
  },
  methods: {
    ...mapActions("service", [
      "ajaxServiceCreateOrUpdate",
      "ajaxServiceDelete"
    ]),
    ...mapActions("tenant", ["ajaxTenantFetchAll"]),

    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    getPublicPages(item) {
      let public_pages = item.public_page ? JSON.parse(item.public_page) : [];
      if (public_pages && public_pages.length > 0) {
        const publicPageArray = _.map(public_pages, 'page_name');
        public_pages = _.join(publicPageArray, ' - ');
      } else {
        public_pages = "-"
      }
      return public_pages;
    },
    async initComp() {
      this.ajaxTenantFetchAll();
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async onDelete() {
      this.$confirm({
        message: this.$t("dialogs.deleteConfirmMessage"),
        button: {
          no: this.$t("dialogs.no"),
          yes: this.$t("dialogs.yes")
        },
        callback: async confirm => {
          if (confirm) {
            const payload = _.clone(this.form);
            await this.deleteHelper(
              this.resourceType,
              this.ajaxServiceDelete,
              payload,
              payload => payload.p.name
            );
            this.$emit("formDelete");
          }
        }
      });
      //if (await this.$refs.alert.show()) {
      //}
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      delete payload['public_page'];
       await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxServiceCreateOrUpdate,
        payload,
        payload => payload.p.name,
        payload => payload.p.name
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.name = row.name;
        this.form.amount = row.amount;
        this.form.hours = row.hours;
        this.form.note = row.note;
        //this.form.public_page = row.public_page;
        this.form.public_page = this.getPublicPages(row);
        this.form.self_generated_file = row.self_generated_file;
        this.form.tenant_id = row.tenant_id;
        this.form.description=row.description;
      } else if (row == null) {
        this.form.id = "";
        this.form.name = "";
        this.form.amount = "";
        this.form.hours = "-1";
        this.form.note = "";
        this.form.public_page = "";
        this.form.description="";
        this.form.self_generated_file = false;
        this.form.tenant_id = this.$props.withModelId || "";
      }
    }
  },
  computed: {
    ...mapGetters("tenant", ["tenants"]),
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-"
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.name || "-"
          });
          break;

        default:
          break;
      }
      return title;
    }
  },
  created() {
    this.initForm();
    this.initComp();
  }
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
