export default {
  TOAST_TYPE: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error"
  },
  FORM_MODE: {
    CREATE: "create",
    UPDATE: "update",
    SHOW: "show",
    READONLY: "readonly",
    SHOW_SUMMARY: "show_summary",
    DELETE: "delete",
    RENEW: "renew"
  },
  GDPR_PUBLIC_PAGE_VISIBILITY: {
    AUTO: "AUTO",
    DISABLED: "DISABLED",
    ENABLED: "ENABLED"
  },
  LIST_MODE: {
    FULL: "full",
    TABBED: "tabbed"
  },
  DISPLAY_AS: {
    TEXT: "text",
    NUMBER: "number",
    BOOLEAN: "boolean",
    LINK: "link",
    SEPARATOR: "separator",
    SUBSEPARATOR: "subseparator"
  },
  TICKET_CREATION: {
    AUTO: "AUTO",
    AUTO_WITH_FORCE: "AUTO_WITH_FORCE",
    NEVER: "NEVER",
    ALWAYS: "ALWAYS"
  },
  TICKET_INTERNAL_STATUS: {
    PENDING: "PENDING",
    STARTED: "STARTED",
    ONHOLD: "ONHOLD",
    FINISHED: "FINISHED"
  },
  TICKET_INTERNAL_STATUS_ACTION: {
    START: "START",
    FINISH: "FINISH",
    HOLD: "HOLD"
  },
  TICKET_STATUS: {
    OPENED: "OPENED",
    CANCELED: "CANCELED",
    CLOSED: "CLOSED"
  },
  TICKET_STATUS_ACTION: {
    CLOSE: "CHIUDI E FATTURA"
  },
  TICKET_SUBTYPE: {
    FLAT: "FLAT",
    TIME_BUDGET: "TIME_BUDGET",
    WARRANTY_ASSISTANCE: "WARRANTY_ASSISTANCE",
    WITHOUT_CONTRACT: "WITHOUT_CONTRACT"
  },
  TICKET_TYPE: {
    ORDER: "ORDER",
    TECH_SUPPORT: "TECH_SUPPORT"
  },
  AGREEMENT_STATUS: {
    ACTIVE: "ACTIVE",
    WARNING: "WARNING",
    EXPIRING: "EXPIRING",
    EXPIRED: "EXPIRED"
  },
  STATE_AGREEMENT: {
    ACTIVE: "ACTIVE",
    WARNING: "WARNING",
    EXPIRING: "EXPIRING",
    EXPIRED: "EXPIRED",
    RENEWED: "RENEWED",
    NOT_RENEWED: "NOT_RENEWED",
    MIGRATED: "MIGRATED",
    INTERRUPTED: "INTERRUPTED",
  },
  STATE_ORDER: {
    OPENED: "OPENED",
    CLOSED: "CLOSED",
    INVOICED: "INVOICED"
  },
  STATE_INVOICE: {
    EMITTED: "EMITTED",
    NOT_EMITTED: "NOT_EMITTED"
  },
  CASHED_INVOICE: {
    CASHED: "YES",
    NOT_CASHED: "NO"
  },
  INVOICE_PERIOD_STATE: {
    NOT_SENT: "NOT_SENT",
    SENT: "SENT"
  },
  INVOICE_PERIOD_DISABLED_OPTION: {
    OMAGGIO: "OMAGGIO",
    RIDISTRIBUITO: "RIDISTRIBUITO"
  },
  SETTING_TYPE: {
    AGREEMENT_DEADLINE: "AGREEMENT_DEADLINE",
    INVOICING: "INVOICING"
  },
  RENEWAL_AGREEMENT_OPTION: {
    SIMPLE_RENEWAL: "SIMPLE_RENEWAL",
    ADVANCED_RENEWAL: "ADVANCED_RENEWAL"
  },
  MODEL_NAME: {
    ACTIVITIES: "activities",
    AGREEMENTS: "agreements",
    ATTACHMENTS: "attachments",
    CUSTOMERS: "customers",
    FOLDERS: "folders",
    GENERATION_SESSIONS: "generation_sessions",
    INVOICE_PERIODS: "invoice_periods",
    INVOICES: "invoices",
    ORDERS: "orders",
    SERVICES: "services",
    TICKET: "tickets",
    USERS: "users",
  },
  ACTION_APPLICATION_LOG: {
    LOGIN: "LOGIN",
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    SEND_EMAIL: "SEND_EMAIL"
  },
  FILE_TYPE: {
    TEMPLATE: "TEMPLATE",
    GENERIC: "GENERIC",
    QUESTIONARIO: "QUESTIONARIO",
    ORDER: "ORDER"
  },
  UPLOAD: {
    YES: "YES",
    NO: "NO"
  },
  AT_SECTION_PAGE: {
    PROGRAMMA_ANNUALE: "programma_annuale",
    CONTO_CONSUNTIVO: "conto_consuntivo",
    RELAZIONI_PA_CC: "relazioni_pa_cc",
    VERBALI_REVISORI_CONTI: "verbali_revisori_conti"
  },
  GENERATE_OPTION: {
    GENERATE_DOCUMENT: "GENERATE_DOCUMENT",
    SEND_DOCUMENT: "SEND_DOCUMENT"
  },
  MACHINE_NAME_TEMPLATE_TYPE: {
    TEMPLATE: "TEMPLATE"
  },
  MACHINE_NAME_DOCUMENT_TYPE: {
    GENERIC_FILES: "GENERIC_FILES"    
  },
  TAG_NAME: {
    PROGRAMMA_ANNUALE: "PROGRAMMA_ANNUALE",
    CONTO_CONSUNTIVO: "CONTO_CONSUNTIVO",
    RELAZIONI_PA_CC: "RELAZIONI_PA_CC",
    VERBALI_REVISORI_CONTI: "VERBALI_REVISORI_CONTI",
    ATTI_AMMINISTRAZIONI_ENTI: "ATTI_AMMINISTRAZIONI_ENTI",
    DETERMINE_DECISIONI_CONTRARRE: "DETERMINE_DECISIONI_CONTRARRE",
    ATTI_NOMINA: "ATTI_NOMINA",
    ATTI_PROCEDURA: "ATTI_PROCEDURA",
    PROCEDURA_AFFIDAMENTO: "PROCEDURA_AFFIDAMENTO"
  },
  PROCEEDING_TYPE: {
    ATTI_PROCEDURA: "ATTI_PROCEDURA",
    DETERMINA: "DETERMINA",
    NOMINA: "NOMINA"
  },
  PROCEEDING_SUBTYPE: {
    ATTI_PROCEDURA: {
      AFFIDAMENTO: "AFFIDAMENTO",
      AVVIO: "AVVIO",
      ATTO_SOTTOMISSIONE: "ATTO_SOTTOMISSIONE",
      CERTIFICATO_CONFORMITA: "CERTIFICATO_CONFORMITA",
      COMPOSIZIONE_COMMISSIONE: "COMPOSIZIONE_COMMISSIONE",
      DISCIPLINARE_GARA: "DISCIPLINARE_GARA",
      LETTERA_INVITO: "LETTERA_INVITO",
      RAPPORTO_PERSONALE_OE: "RAPPORTO_PERSONALE_OE",
      RELAZIONE_CERTIFICAZIONE_OE: "RELAZIONE_CERTIFICAZIONE_OE",
      RIEPILOGO_TD: "RIEPILOGO_TD",
      STIPULA_TD: "STIPULA_TD",
      VERBALI_COMMISSIONI: "VERBALI_COMMISSIONI"
    },
    DETERMINA: {
      CONTRARRE: "CONTRARRE",
      //AVVIO: "AVVIO",
      //AFFIDAMENTO: "AFFIDAMENTO",
    }
  }
};
