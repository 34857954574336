/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { AbilityBuilder, Ability } from "@casl/ability";
import _ from "lodash";

function subjectName(item) {
  if (!item || typeof item === "string" || !user) {
    return item;
  }
}

export default function defineAbilitiesFor(user) {
  const { can, cannot, rules } = AbilityBuilder.extract();
  if (user == null || user.info == null || user.info.role == null) {
    return null;
  }

  // const roleName = user.info.role.name || 'none'
  let roleName = _.get(user, "info.role.name", "none");
  const ability = AbilityBuilder.define({ subjectName }, can => {
    //if (roleName === "customer") can("attachments", "app")
    //if (roleName === "customer") can("route", "attachments")
    if (roleName === "super_admin" || roleName === "admin") can("reports", "app")
    if (roleName === "super_admin" || roleName === "admin") can("route", "reports")
    //app
    if (
      roleName === "customer"
    )
      can("dashboard", "app");
    if (
      roleName === "super_admin" ||
      roleName === "admin" ||
      roleName === "super_office" ||
      roleName === "office"
    )
      can("tenants", "app");
    if (
      roleName === "admin" ||
      roleName === "super_office" ||
      roleName === "office" ||
      roleName === "operator" ||
      roleName === "extATOperator"
    )
      can("customers", "app");
    if (
      roleName === "super_office" ||
      roleName === "office" ||
      roleName === "operator" ||
      roleName === "admin"
    )
      can("tickets", "app");
    if (roleName === "super_admin" || roleName === "admin" || roleName === "super_office" || roleName === "extATOperator") can("users", "app");
    if (
      roleName === "admin" ||
      roleName === "super_admin" ||
      roleName === "super_office" ||
      roleName === "office"
    )
      can("services", "app");
    if (
      roleName === "admin" ||
      roleName === "super_office" ||
      roleName === "office" ||
      roleName === "operator" ||
      roleName === "extATOperator"
    )
      can("agreements", "app");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office") can("orders", "app");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("generation_sessions", "app");
    if (roleName === "super_admin") can("application_settings", "app");
    if (roleName === "super_admin" || roleName === "admin" || roleName === "super_office" || roleName === "office") can("email_messages", "app");
    if (roleName === "admin") can("application_log", "app");
    if (roleName === "super_admin" || roleName === "admin" || roleName === "super_office" || roleName === "office") can("invoices", "app");

    //application_settings
    if (roleName === "super_admin") can("route", "application_settings");

    //agreements
    if (
      roleName === "admin" ||
      roleName === "super_office" ||
      roleName === "office" ||
      roleName === "operator" ||
      roleName === "extATOperator"
    )
      can("route", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "extATOperator")
      can("detailsButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("addButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "extATOperator")
      can("editButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("generateFilesButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("generateGenericFilesButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("sendGenericFilesButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("invoiceButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("renewButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("interruptButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("filterButton", "agreements");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("addButton", "folders");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("editButton", "folders");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("deleteButton", "folders");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("addButton", "proceedings");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("editButton", "proceedings");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("deleteButton", "proceedings");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "extATOperator")
      can("addButton", "links");
    if (roleName === "customer") {
      can("uploadButton", "folders");
      can("uploadButton", "permalinks");
      can("deleteButton", "permalinks");
    }
    //customers
    if (
      roleName === "admin" ||
      roleName === "super_office" || 
      roleName === "office" ||
      roleName === "operator" ||
      roleName === "extATOperator"
    )
      can("route", "customers");
    if (roleName === "admin" || roleName === "super_office" || roleName === "office" || roleName === "customer")
      can("addButton", "customers");

    //generation_sessions
    if (roleName === "admin" || roleName === "super_office" || roleName === "office")
      can("route", "generation_sessions");

    //orders
    if (roleName === "admin" || roleName === "super_office" || roleName === "office") can("route", "orders");

    //tenants
    if (
      roleName === "super_admin" ||
      roleName === "admin" ||
      roleName === "super_office" || 
      roleName === "office"
    )
      can("route", "tenants");
    if (roleName === "super_admin") can("addButton", "tenants");

    //tickets
    if (
      roleName === "super_office" || 
      roleName === "office" ||
      //roleName === "customer" ||
      roleName === "operator" ||
      roleName === "admin"
    )
      can("route", "tickets");

    //ticketsShow
    if (
      roleName === "super_office" || 
      roleName === "office" ||
      //roleName === "customer" ||
      roleName === "operator" ||
      roleName === "admin"
    )
      can("route", "ticketsShow");

    //users
    if (roleName === "super_admin" || roleName === "admin" || roleName === "super_office" || roleName === "extATOperator") can("route", "users");

    //services
    if (
      roleName === "super_admin" ||
      roleName === "admin" ||
      roleName === "super_office" || 
      roleName === "office"
    )
      can("route", "services");

    if (
      roleName === "customer"
    )
      can("route", "dashboard");
     //can("route", "attachments");

    //application_log
    can("route", "application_log");
  });

  return ability.rules;
}
