<template>
  <v-container fluid class="form-container title">
    <v-card-title class="form-title title">
      {{ this.formTitle | capitalize }}
    </v-card-title>
    <v-stepper v-model="e6_step" vertical>
      <!-- STEP 1 : SELEZIONE DEI CLIENTI -->
      <v-stepper-step :complete="e6_step > 1" step="1">{{
        $t("forms.generation_sessions.section1")
      }}</v-stepper-step>
      <v-stepper-content step="1">
        <v-text-field
          v-model="search1"
          append-icon="search"
          :label="$t('misc.search')"
          single-line
          hide-details
          style="max-width: 500px; min-width: 250px"
        ></v-text-field>
        <v-progress-linear
          v-if="customersOnLoading"
          color="info"
          height="5"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          v-model="customersSelected"
          v-bind:headers="this.tableData1.headers"
          v-bind:items="this.tableData1.items"
          v-bind:options.sync="tableOptions1"
          :server-items-length="customersTotal"
          :search="search1"
          show-select
          item-key="code"
          class="elevation-1"
          @toggle-select-all="handleToggleAll1($event)"
          ref="dTable1"
        >
        </v-data-table>
        <FormButtons
          :multiForm="true"
          :multiLayout="0"
          @onNext="onNext"
          @onCancel="onCancel"
        />
      </v-stepper-content>
      <!-- STEP 2 : SELEZIONE DEI TEMPLATE -->
      <v-stepper-step :complete="e6_step > 2" step="2">{{
        $t("forms.generation_sessions.section2")
      }}</v-stepper-step>
      <v-stepper-content step="2">
        <v-text-field
          v-model="search2"
          append-icon="search"
          :label="$t('misc.search')"
          single-line
          hide-details
          style="max-width: 500px; min-width: 250px"
        ></v-text-field>
        <v-progress-linear
          v-if="attachmentTypesOnLoading"
          color="info"
          height="5"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          v-model="templatesSelected"
          v-bind:headers="this.tableData2.headers"
          v-bind:items="this.tableData2.items"
          v-bind:options.sync="tableOptions2"
          :server-items-length="attachmentTypesTotal"
          :search="search2"
          show-select
          item-key="machine_name"
          class="elevation-1"
          @toggle-select-all="handleToggleAll2($event)"
          ref="dTable2"
        >
        </v-data-table>
        <FormButtons
          :multiForm="true"
          :multiLayout="1"
          @onBack="onBack"
          @onNext="onNext"
          @onCancel="onCancel"
        />
      </v-stepper-content>
      <!-- STEP 3 : STABILIRE SE ALLEGARE I FILE -->
      <v-stepper-step :complete="e6_step > 3" step="3">{{
        $t("forms.generation_sessions.section3")
      }}</v-stepper-step>
      <v-stepper-content step="3">
        <v-checkbox
          v-model="form.allegato"
          primary
          hide-details
          :label="$t('forms.generation_sessions.attach')"
        >
        </v-checkbox>
        <FormButtons
          :multiForm="true"
          :multiLayout="1"
          @onBack="onBack"
          @onNext="onNext"
          @onCancel="onCancel"
        />
      </v-stepper-content>

      <!-- STEP 4 : RESOCONTO-->
      <v-stepper-step :complete="e6_step > 4" step="4">{{
        $t("forms.generation_sessions.section4")
      }}</v-stepper-step>
      <v-stepper-content step="4">
        <v-card color="grey lighten-5" class="mb-5">
          <v-card-title
            >{{ $t("forms.generation_sessions.section1") }} ({{
              customersSelected.length
            }}
            {{ $t("forms.generation_sessions.selected") }})</v-card-title
          >
          <v-data-table
            v-bind:headers="this.tableData1.headers"
            v-bind:items="customersSelected"
            v-bind:options.sync="tableOptions3"
            item-key="code"
          >
            <template slot="items" slot-scope="props">
              <td style="width: 30%">{{ props.item.code }}</td>
              <td style="width: 30%">
                {{ props.item.mechanographyc_code }}
              </td>
              <td style="width: 40%">{{ props.item.name }}</td>
            </template>
          </v-data-table>
          <v-card-title
            >{{ $t("forms.generation_sessions.section2") }} ({{
              templatesSelected.length
            }}
            {{ $t("forms.generation_sessions.selected") }})</v-card-title
          >
          <v-data-table
            v-bind:headers="this.tableData2.headers"
            v-bind:items="templatesSelected"
            v-bind:options.sync="tableOptions4"
            item-key="machine_name"
          >
            <template slot="items" slot-scope="props">
              <td style="width: 30%">{{ props.item.machine_name }}</td>
              <td style="width: 70%">{{ props.item.name }}</td>
            </template>
          </v-data-table>
          <v-checkbox
            :input-value="form.allegato"
            primary
            hide-details
            readonly
            :label="$t('forms.generation_sessions.attach')"
          >
          </v-checkbox
          ><br />
        </v-card>
        <FormButtons
          :multiForm="true"
          :multiLayout="2"
          @onBack="onBack"
          @onSave="onSubmit"
          @onCancel="onCancel"
        />
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";
import _ from "lodash";
import { HelperService } from "@/services/helper.service";
import DatatablesHelperMulti from "@/mixins/datatablesHelperMulti";

export default {
  name: "GenerationSessionForm",
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String,
    },
    advancedRenewalMode: {
      default: false,
      type: Boolean,
    },
    selectedItem: Object,
  },
  data() {
    return {
      customersSelected: [],
      templatesSelected: [],
      search1: "",
      search2: "",
      progress: true,
      resourceType: this.$t("resource_types.session"),
      form: {
        id: "",
        allegato: false,
        customers: [],
        templates: [],
      },
      e6_step: 1,
      tableOptions1: {
        itemsPerPage: 5,
      },
      tableOptions2: {
        itemsPerPage: 5,
      },
      tableOptions1t: {
        itemsPerPage: 10000,
      },
      tableOptions2t: {
        itemsPerPage: 10000,
      },
      tableOptions3: {
        itemsPerPage: 5,
      },
      tableOptions4: {
        itemsPerPage: 5,
      },
    };
  },
  components: { FormButtons },
  mixins: [DatatablesHelperMulti, SharedMixin, Enums],
  watch: {
    search1: _.debounce(async function () {
      this.fetchData1();
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    search2: _.debounce(async function () {
      this.fetchData2();
    }, process.env.VUE_APP_DEBOUNCE_TIME),
  },
  methods: {
    ...mapActions("generationSession", ["ajaxGenerationSessionCreateOrUpdate"]),
    ...mapActions("customer", [
      "ajaxCustomerFetchAll",
      "ajaxCustomerFetchTemp",
    ]),
    ...mapActions("attachmentType", [
      "ajaxAttachmentTypeFetchAll",
      "ajaxAttachmentTypeFetchTemp",
    ]),
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.e6_step = 1;
        this.setForm();
        this.resetSelected();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
    async initComp() {
      if (this.mode != Enums.FORM_MODE.CREATE) {
        await this.updateSelected();
      }
      this.fetchData1();
      this.fetchData2();
    },
    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async onBack() {
      this.e6_step--;
    },
    async onNext() {
      this.e6_step++;
    },
    debounceFetch1: _.debounce(function () {
      this.fetchData1();
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    debounceFetch2: _.debounce(function () {
      this.fetchData2();
    }, process.env.VUE_APP_DEBOUNCE_TIME),
    handlePaginationChanged1() {
      this.debounceFetch1();
    },
    handlePaginationChanged2() {
      this.debounceFetch2();
    },
    async handleToggleAll1(e) {
      if (e.value) {
        await this.fetchData1(null, true);
        this.customersSelected = this.customersTemp.filter(item =>  item.piva != "93158770870" && item.piva != "04751020878");
      } else {
        this.customersSelected = [];
      }
    },
    async handleToggleAll2(e) {
      if (e.value) {
        await this.fetchData2(null, true);
        this.templatesSelected = this.attachmentTypesTemp;
      } else {
        this.templatesSelected = [];
      }
    },
    async submitToStore() {
      const payload = _.clone(this.formGetter);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxGenerationSessionCreateOrUpdate,
        payload,
        (payload) => payload.v.id,
        (payload) => payload.p.id
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.allegato = row.allegato;
        this.form.customers = row.customers;
        this.form.templates = row.templates;
      } else if (row == null) {
        this.form.id = "";
        this.form.allegato = true;
        this.form.customers = [];
        this.form.templates = [];
      }
    },
    async updateSelected() {
      if (this.form.customers.length > 0) {
        await this.ajaxCustomerFetchTemp({
          filter: { id: this.form.customers.join(",") },
          pagination: this.tableOptions1t,
        });
        this.customersSelected = _.cloneDeep(this.customersTemp);
      }
      if (this.form.templates.length > 0) {
        await this.ajaxAttachmentTypeFetchTemp({
          filter: { machine_name: this.form.templates.join(",") },
          pagination: this.tableOptions2t,
          machineName: "TEMPLATE",
        });
        this.templatesSelected = _.cloneDeep(this.attachmentTypesTemp);
      }
    },
    resetSelected() {
      this.customersSelected = [];
      this.templatesSelected = [];
    },
    // async fetchData1(needle = null, temp=false) {
    //   const _search = needle ? needle : this.search1 || "";

    //   let payloadFAP = HelperService.buildFilterAndPagination({
    //     search: _search,
    //     pagination: temp?this.tableOptions1t:this.tableOptions1
    //   });

    //   if (!temp) {
    //     await this.ajaxCustomerFetchAll(payloadFAP);
    //     this.initTable1();
    //   }
    //   else await this.ajaxCustomerFetchTemp(payloadFAP);
    // },
    async fetchData1(needle = null, temp = false) {
      const _search = needle ? needle : this.search1 || "";
      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        pagination: temp ? this.tableOptions1t : this.tableOptions1,
        filter: {
          tenant_id: 1,
        },
        filter_criteria: {
          having_agreement_of_type: ["GDPR"],
          having_active_agreement: "SI",
        },
      });
      if (!temp) {
        await this.ajaxCustomerFetchAll(payloadFAP);
        this.initTable1();
      } else await this.ajaxCustomerFetchTemp(payloadFAP);
    },
    async fetchData2(needle = null, temp = false) {
      const _search = needle ? needle : this.search2 || "";

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        pagination: temp ? this.tableOptions2t : this.tableOptions2,
      });
      payloadFAP.machineName = "TEMPLATE";

      if (!temp) {
        await this.ajaxAttachmentTypeFetchAll(payloadFAP);
        this.initTable2();
      } else await this.ajaxAttachmentTypeFetchTemp(payloadFAP);
    },
    initTable1() {
      this.tableData1.headers = this.mapHeadersCustomers();
      this.tableData1.items = this.mapItemsCustomers();
    },
    initTable2() {
      this.tableData2.headers = this.mapHeadersTemplates();
      this.tableData2.items = this.mapItemsTemplates();
    },
    getCodeByCustomerType(item) {
      return item.mechanographyc_code ? item.mechanographyc_code : item.piva;
    },
    mapHeadersCustomers() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Code",
        value: "code",
        sortable: true,
        align: "start",
      });
      tableHeaders.push({
        text: "Mechanographyc",
        value: "mechanographyc_code",
        align: "start",
        sortable: true,
      });
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true,
      });
      return tableHeaders;
    },
    mapItemsCustomers() {
      let map = [];
      let customerTemp = this.customers.filter(item =>  item.piva != "93158770870" && item.piva != "04751020878")
      map = _.map(customerTemp, (item) => {
        item.fields = {
          code: {
            data: this.getCodeByCustomerType(item),
            dataType: "text",
            css: "text-xs-left",
          },
          mechanographyc_code: {
            data: item.mechanographyc_code,
            dataType: "text",
            css: "text-xs-left",
          },
          name: { data: item.name, dataType: "text", css: "text-xs-left" },
        };
        return item;
      });
      return map;
    },
    mapHeadersTemplates() {
      const tableHeaders = [];
      tableHeaders.push({
        text: "Machine Name",
        value: "machine_name",
        align: "start",
        sortable: true,
      });
      tableHeaders.push({
        text: "Name",
        value: "name",
        align: "start",
        sortable: true,
      });
      return tableHeaders;
    },
    mapItemsTemplates() {
      let map = [];
      map = _.map(this.attachmentTypes, (item) => {
        item.fields = {
          machine_name: {
            data: item.machine_name,
            dataType: "text",
            css: "text-xs-left",
          },
          name: { data: item.name, dataType: "text", css: "text-xs-left" },
        };
        return item;
      });
      return map;
    },
  },
  computed: {
    ...mapGetters("customer", {
      customers: "customers",
      customersTemp: "customersTemp",
      customersTotal: "total",
      customersOnLoading: "onLoading",
    }),
    ...mapGetters("attachmentType", {
      attachmentTypes: "attachmentTypes",
      attachmentTypesTemp: "attachmentTypesTemp",
      attachmentTypesTotal: "total",
      attachmentTypesOnLoading: "onLoading",
    }),
    formGetter: (state) => {
      state.form.customers = _.map(state.customersSelected, "id");
      state.form.templates = _.map(state.templatesSelected, "machine_name");
      return JSON.parse(JSON.stringify(state.form));
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-",
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.id ? this.form.id : "-",
          });
          break;

        default:
          break;
      }
      return title;
    },

    Enums() {
      return Enums;
    },
  },
  created() {
    this.initForm();
    this.initComp();
  },
  mounted() {
    this.$refs.dTable1.$on("update:options", this.handlePaginationChanged1);
    this.$refs.dTable2.$on("update:options", this.handlePaginationChanged2);
  },
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
